<template>
  <div class="bookingBlock__content">
    <div class="bookingBlock__content-inner">
      <div class="flightBooking">
        <vue-scroll :ops="ops">
          <div class="flightBooking__content">
            <div class="flightBooking__content-left">
              <vue-scroll :ops="ops">
                <FlightList :page="'flight'"/>
              </vue-scroll>
            </div>
            <div class="flightBooking__content-right">
              <div class="flightBooking__info">
                <article>
                  <p>Requested Departure and Arrival times <b>need to be confirmed by FLYJETS and the AIRCRAFT
                    PROVIDER.</b></p>
                  <p>Requested times will be accomodated to the best of our ability. Should you wish to confirm a
                    specific time before booking, <b>please contact us directly</b> and we will work with you to confirm
                    availability</p>
                </article>
                <!--              <div class="flightBooking__info-decor">-->
                <!--                <SvgIcon name="line-decor-1"/>-->
                <!--              </div>-->
                <!--              <div class="flightBooking__info-decor flightBooking__info-decor&#45;&#45;mobile">-->
                <!--                <SvgIcon name="decor-line-2"/>-->
                <!--              </div>-->
                <div class="flightBooking__info-decor--contact">
                  <SvgIcon name="line-decor-1"/>
                </div>
                <div class="flightBooking__info-decor--contact flightBooking__info-decor--contact--mobile">
                  <SvgIcon name="decor-line-2"/>
                </div>
              </div>
            </div>
          </div>
        </vue-scroll>
      </div>
      <router-link class="bookingBlock__content-next js-bookingNav-item" to="aircraft">
          <span class="icon">
            <SvgIcon name="right-arrow"/>
          </span>
        <span class="text">Next</span>
      </router-link>
    </div>
  </div>
</template>

<script>
  import SvgIcon from '@/components/common/SvgIcon/SvgIcon'
  import FlightList from '@/components/FlightList/FlightList'

  export default {
    name: 'flight',
    data: () => ({
      ops: {
        rail: {
          size: '5px',
        },
        bar: {
          size: '5px',
          background: 'rgba(244, 244, 244, 0.2)',
        },
      },
    }),
    components: {
      SvgIcon,
      FlightList,
    },
  }
</script>
