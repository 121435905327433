<template>
  <div>
    <div class="flightBooking__list">
      <div v-if="page === 'confirmation'" class="flightBooking__list-item">
        <div class="flightBooking__list-item-left">
          <div class="flightBooking__list-item-icon">
            <SvgIcon name="ticket"/>
          </div>
          <div class="flightBooking__list-item-caption">Tail number:</div>
        </div>
        <div class="flightBooking__list-item-right">
          <div class="flightBooking__list-item-title">56HNkyt</div>
        </div>
      </div>

      <div v-if="page === 'confirmation'" class="flightBooking__list-item">
        <div class="flightBooking__list-item-left">
          <div class="flightBooking__list-item-icon">
            <SvgIcon name="operator"/>
          </div>
          <div class="flightBooking__list-item-caption">Operator:</div>
        </div>
        <div class="flightBooking__list-item-right">
          <div class="flightBooking__list-item-title">FLY&nbsp;JETS</div>
          <div
            class="flightBooking__list-item-text flightBooking__list-item-text--small">247&nbsp;Name&nbsp;Street,
            123&nbsp;floor,<br/>New&nbsp;York&nbsp;NY&nbsp;18001,<br/>(1232)&nbsp;567-765-45
          </div>
        </div>
      </div>

      <div v-if="page === 'flight'" class="flightBooking__title">Outbound Flight</div>

      <div class="flightBooking__list-item">
        <div class="flightBooking__list-item-left">
          <div class="flightBooking__list-item-icon">
            <SvgIcon name="departures"/>
          </div>
          <div class="flightBooking__list-item-caption-wr">
            <div class="flightBooking__list-item-caption">Departure address:</div>
            <router-link class="flightBooking__list-item-link" to="#">To see location on map</router-link>
          </div>
        </div>
        <div class="flightBooking__list-item-right">
          <div class="flightBooking__list-item-title">{{bookingData.departure}}</div>
          <!--        <div class="flightBooking__list-item-text">7150 Republic Airport, Farmingdale, NY 11735</div>-->
          <!--        <div class="flightBooking__list-item-info">Fixed Base Operator Location To Be Confirmed</div>-->
        </div>
      </div>

      <div class="flightBooking__list-item">
        <div class="flightBooking__list-item-left">
          <div class="flightBooking__list-item-icon">
            <SvgIcon name="arrival"/>
          </div>
          <div class="flightBooking__list-item-caption-wr">
            <div class="flightBooking__list-item-caption">Arrival address:</div>
            <router-link class="flightBooking__list-item-link" to="#">To see location on map</router-link>
          </div>
        </div>
        <div class="flightBooking__list-item-right">
          <div class="flightBooking__list-item-title">{{bookingData.arrival}}</div>
          <!--        <div class="flightBooking__list-item-text">1 Jeff Fuqua Boulevard Orlando, Florida 32827</div>-->
        </div>
      </div>

      <!-- <div v-if="page === 'flight'" class="flightBooking__list-item flightBooking__list-item--form">
        <div class="flightBooking__list-item-left">
          <div class="flightBooking__list-item-icon">
            <SvgIcon name="clock3"/>
          </div>
          <div class="flightBooking__list-item-caption">Request departure time:</div>
        </div>
        <div class="flightBooking__list-item-right">
          <DepartureTime/>
        </div>
      </div> -->

      <div v-if="page === 'flight'" class="flightBooking__title" style="margin-top: 20px">Inbound Flight</div>

      <div class="flightBooking__list-item">
        <div class="flightBooking__list-item-left">
          <div class="flightBooking__list-item-icon">
            <SvgIcon name="departures"/>
          </div>
          <div class="flightBooking__list-item-caption-wr">
            <div class="flightBooking__list-item-caption">Departure address:</div>
            <router-link class="flightBooking__list-item-link" to="#">To see location on map</router-link>
          </div>
        </div>
        <div class="flightBooking__list-item-right">
          <div class="flightBooking__list-item-title">{{bookingData.arrival}}</div>
          <!--        <div class="flightBooking__list-item-text">7150 Republic Airport, Farmingdale, NY 11735</div>-->
          <!--        <div class="flightBooking__list-item-info">Fixed Base Operator Location To Be Confirmed</div>-->
        </div>
      </div>

      <div class="flightBooking__list-item">
        <div class="flightBooking__list-item-left">
          <div class="flightBooking__list-item-icon">
            <SvgIcon name="arrival"/>
          </div>
          <div class="flightBooking__list-item-caption-wr">
            <div class="flightBooking__list-item-caption">Arrival address:</div>
            <router-link class="flightBooking__list-item-link" to="#">To see location on map</router-link>
          </div>
        </div>
        <div class="flightBooking__list-item-right">
          <div class="flightBooking__list-item-title">{{bookingData.departure}}</div>
          <!--        <div class="flightBooking__list-item-text">1 Jeff Fuqua Boulevard Orlando, Florida 32827</div>-->
        </div>
      </div>

      <!-- <div v-if="page === 'flight'" class="flightBooking__list-item flightBooking__list-item--form">
        <div class="flightBooking__list-item-left">
          <div class="flightBooking__list-item-icon">
            <SvgIcon name="clock3"/>
          </div>
          <div class="flightBooking__list-item-caption">Request return time:</div>
        </div>
        <div class="flightBooking__list-item-right">
          <ArrivalTime/>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
  import SvgIcon from '@/components/common/SvgIcon/SvgIcon';
  // import DepartureTime from '@/components/Forms/flight/DepartureTime';
  // import ArrivalTime from '@/components/Forms/flight/ArrivalTime';

  export default {
    name: 'FlightList',
    props: ['page'],
    computed: {
      bookingData () {
        return this.$store.state.bookingFromSearch.fullBookingItem
      },
    },
    components: {
      SvgIcon,
      // DepartureTime,
      // ArrivalTime,
    },
  };
</script>
